import React from 'react'
import styled from 'styled-components'
import { COLOR_CONSTANTS, fontSizes } from 'theme'
import { SCHEDULE_DEMO_LINK, CONTAINER_MAX_WIDTH } from 'consts'
import { pxToRem } from 'helpers'
import { Flex, Box } from 'components/Layout'
import { Text } from 'components/Typography'
import LocalImage from 'components/LocalImage'
import Container from 'components/Container'
import Button from 'components/Button'

import imageGetStarted from 'static/images/solutions_multi_location_brands/get_started.webp'

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  background: ${({ bg }) => bg || COLOR_CONSTANTS.WHITE};
`

const StyledScheduleDemoButton = styled(Button.Gradient)`
  font-size: ${fontSizes.m};
  height: 100%;
  padding-left: ${pxToRem(48)};
  padding-right: ${pxToRem(48)};
  box-shadow: 0px 18px 20px -8px rgba(12, 35, 68, 0.16);
  background: ${COLOR_CONSTANTS.DENIM};
  color: ${COLOR_CONSTANTS.WHITE};
  border: 1px solid ${COLOR_CONSTANTS.WHITE};
  &:hover {
    background: ${COLOR_CONSTANTS.WHITE};
    color: ${COLOR_CONSTANTS.DENIM};
    border: 1px solid ${COLOR_CONSTANTS.DENIM};
  }
`

const GetStartedComponent2 = () => {
  return (
    <Wrapper>
      <Container
        pl={{ mobile: 'l', tablet: 'l' }}
        pr={{ mobile: 'l', tablet: 'l' }}
        py={{ mobile: 'l', tablet: 'xxl' }}
        position="relative"
        maxWidth={CONTAINER_MAX_WIDTH}
        width="100%"
        height="100%"
      >
        <Flex
          justifyContent={{ mobile: 'center', desktop: 'space-between' }}
          flexDirection={{ mobile: 'column', desktop: 'row' }}
          alignItems="center"
        >
          <Flex mt={{ mobile: 'l', desktop: '0' }} order={{ mobile: 2, desktop: 2 }} maxWidth="517px" width="100%">
            <LocalImage src={imageGetStarted} width="100%" alt="Want to get started?" />
          </Flex>
          <Flex
            order={{ mobile: 1, desktop: 1 }}
            flexDirection="column"
            alignItems={{ mobile: 'center', desktop: 'flex-start' }}
            maxWidth={{ mobile: '100%', desktop: '540px' }}
          >
            <Text
              fontWeight="bold"
              fontSize="3xl"
              color={COLOR_CONSTANTS.DENIM}
              textAlign={{ mobile: 'center', desktop: 'left' }}
            >
              Want to get{' '}
              <Text as="span" fontWeight="bold" fontSize="3xl" color="primary">
                started{' '}
              </Text>
              ?
            </Text>
            <Text mt="m" pt="s" color="secondaryText" fontSize="l" textAlign={{ mobile: 'center', desktop: 'left' }}>
              While Vista Social is completely do-it-yourself and quite easy to get setup with multitude of support
              articles and training videos, we do recommend that you schedule a call with our team to learn more about
              how to deploy Vista Social for your multi location business
            </Text>
            <Flex mt="l" pt="m">
              <Flex height={{ mobile: pxToRem(56), tablet: pxToRem(52) }}>
                <StyledScheduleDemoButton
                  as="a"
                  href={SCHEDULE_DEMO_LINK}
                  target="_blank"
                  rel="noreferrer noopener nofollow"
                >
                  Schedule a demo
                </StyledScheduleDemoButton>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Container>
    </Wrapper>
  )
}

GetStartedComponent2.defaultProps = {}

GetStartedComponent2.propTypes = {}

export default GetStartedComponent2
