import React from 'react'
import styled from 'styled-components'
import { COLOR_CONSTANTS } from 'theme'
import { Flex } from 'components/Layout'
import { Text } from 'components/Typography'
import LocalImage from 'components/LocalImage'
import Container from 'components/Container'

import imageReviewPerson from 'static/images/solutions_enterprise/review_person.webp'
import imageQuote from 'static/images/solutions_enterprise/quote.svg'
import imageDoubleArrowRightBlue from 'static/images/home/double_arrow_right_blue.svg'

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  background: ${({ bg }) => bg || COLOR_CONSTANTS.WHITE};
`

const StyledLinkText = styled(Text)`
  display: flex;
  align-items: center;
`

const LocalImageQuoteReverted = styled(LocalImage)`
  transform: rotate(-180deg);
`

const ReviewComponent = () => {
  return (
    <Wrapper>
      <Container
        pl={{ mobile: 'l', tablet: 'l' }}
        pr={{ mobile: 'l', tablet: 'l' }}
        py={{ mobile: 'l', tablet: 'xxl' }}
        position="relative"
        maxWidth="890px"
        width="100%"
        height="100%"
      >
        <Flex
          justifyContent={{ mobile: 'center', tablet: 'space-between' }}
          flexDirection={{ mobile: 'column', tablet: 'row' }}
          alignItems={{ mobile: 'center', tablet: 'flex-start' }}
        >
          <Flex
            mt={{ mobile: 'l', tablet: 'l' }}
            order={{ mobile: 2, tablet: 1 }}
            minWidth={{ mobile: '100%', tablet: '222px' }}
            maxWidth={{ mobile: '100%', tablet: '222px' }}
            flexDirection={{ mobile: 'row', tablet: 'column' }}
            alignItems="center"
          >
            <LocalImage
              src={imageReviewPerson}
              width="100%"
              maxWidth={{ mobile: '100px', tablet: '100%' }}
              alt="I absolutely love the Vista Social Planner and how easy it is to get a view of my grid and move around my posts before publishing them"
            />
            <Flex
              flexDirection="column"
              mt={{ mobile: '0', tablet: 'm' }}
              pt={{ mobile: '0', tablet: 's' }}
              ml={{ mobile: 'm', tablet: 0 }}
              alignItems={{ mobile: 'flex-start', tablet: 'center' }}
            >
              <Text color={COLOR_CONSTANTS.DENIM} fontWeight="medium" fontSize="xl">
                Joselyn Flores
              </Text>
              <Text color="secondaryText" fontSize="m">
                Owner at Joselyn Flores LLC
              </Text>
            </Flex>
          </Flex>
          <Flex
            order={{ mobile: 1, tablet: 2 }}
            flexDirection="column"
            alignItems={{ mobile: 'center', tablet: 'flex-start' }}
            maxWidth={{ mobile: '100%', tablet: '540px' }}
          >
            <Flex display={{ mobile: 'none', tablet: 'flex' }}>
              <LocalImage src={imageQuote} width="100%" height="48px" />
            </Flex>
            <Text mt="m" pt="s" color="secondaryText" fontSize="xxxl" textAlign="left" fontWeight="medium">
              I absolutely love the Vista Social Planner and how easy it is to get a view of my grid and move around my
              posts before publishing them
            </Text>

            <Flex
              mt="m"
              justifyContent={{ mobile: 'flex-end', tablet: 'space-between' }}
              width="100%"
              alignItems="flex-end"
            >
              <StyledLinkText as="a" href="/integrations" textAlign="left">
                <Text fontSize="l" fontWeight="medium" textAlign="left" color="primary" as="span">
                  READ FULL REVIEW ON G2
                </Text>
                <LocalImage src={imageDoubleArrowRightBlue} />
              </StyledLinkText>
              <Flex display={{ mobile: 'none', tablet: 'flex' }}>
                <LocalImageQuoteReverted src={imageQuote} width="100%" height="48px" />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Container>
    </Wrapper>
  )
}

ReviewComponent.defaultProps = {}

ReviewComponent.propTypes = {}

export default ReviewComponent
