import React from 'react'
import styled from 'styled-components'
import App from 'App'
import { COLOR_CONSTANTS, fontSizes, lineHeights } from 'theme'
import { CONTAINER_MAX_WIDTH, FREQUENTLY_ASKED_QUESTIONS, SCHEDULE_DEMO_LINK } from 'consts'
import { pxToRem } from 'helpers'
import SEO from 'components/SEO'
import { Flex, Grid } from 'components/Layout'
import Container from 'components/Container'
import { Text, H1, H2, H3 } from 'components/Typography'
import Footer from 'components/Footer'
import LocalImage from 'components/LocalImage'
import G2FeaturesComponent from 'components/G2FeaturesComponent'
import Button from 'components/Button'

import imageHeader from 'static/images/solutions_enterprise/header_picture.webp'

import imageDoubleArrowRightBlue from 'static/images/home/double_arrow_right_blue.svg'

import ExploreFullPlatform from 'components/GetStartedComponent'
import GridDropdown from '../../Faq/GridDropdown'
import SecurePlatformComponent from '../components/SecurePlatformComponent'
import GetStartedComponent from '../components/GetStartedComponent'
import ExpectComponent from '../components/ExpectComponent'
import IntegrationsComponent from '../components/IntegrationsComponent'
import ReviewComponent from '../components/ReviewComponent'
import FeaturesComponent from '../components/FeaturesComponent'
import GetStartedComponent2 from '../components/GetStartedComponent2'

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  background: ${({ bg }) => bg || COLOR_CONSTANTS.WHITE};
`

const StyledLinkText = styled(Text)`
  display: flex;
  align-items: center;
`

const StyledScheduleDemoButton = styled(Button.Gradient)`
  font-size: ${fontSizes.m};
  height: 100%;
  padding-left: ${pxToRem(48)};
  padding-right: ${pxToRem(48)};
  box-shadow: 0px 18px 24px -8px rgba(36, 130, 253, 0.32);
`

const SolutionsEnterprise = () => {
  return (
    <App fullHeader>
      <SEO
        title="Vista Social for Enterprise"
        description="If you have a large organization with social media marketing needs beyond our standard plans, Vista Social Enterprise Solutions team is here for you!"
        path="/enterprise/"
      />

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex flexDirection="column" alignItems="center">
            <Grid
              gridTemplateColumns={{ mobile: '1fr', desktop: '1fr 1fr' }}
              gridGap="l"
              width="100%"
              alignItems="center"
              position="relative"
            >
              <Flex height="100%" width="100%" justifyContent="center" alignItems="center">
                <Flex flexDirection="column" maxWidth={{ mobile: '100%', desktop: '485px' }}>
                  <H1
                    fontSize="4xl"
                    fontWeight="bold"
                    color={COLOR_CONSTANTS.DENIM}
                    textAlign={{ mobile: 'center', desktop: 'left' }}
                  >
                    Vista Social for{' '}
                    <H1
                      as="span"
                      fontSize="3xl"
                      fontWeight="bold"
                      color="primary"
                      textAlign={{ mobile: 'center', desktop: 'left' }}
                    >
                      Enterprise
                    </H1>
                  </H1>
                  <Flex mt="m" pt="s">
                    <H2
                      color="secondaryText"
                      fontSize="m"
                      textAlign={{ mobile: 'center', desktop: 'left' }}
                      fontWeight="normal"
                      lineHeight={lineHeights.xxl}
                    >
                      If you have a large organization with social media management needs beyond our standard plans,
                      Vista Social Enterprise Solutions team is here for you!
                    </H2>
                  </Flex>

                  <Flex mt="l" pt="m">
                    <Flex
                      height={{ mobile: pxToRem(56), tablet: pxToRem(52) }}
                      width="100%"
                      justifyContent={{ mobile: 'center', desktop: 'flex-start' }}
                    >
                      <StyledScheduleDemoButton
                        as="a"
                        href={SCHEDULE_DEMO_LINK}
                        target="_blank"
                        rel="noreferrer noopener nofollow"
                      >
                        Schedule a demo
                      </StyledScheduleDemoButton>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
              <Flex width="100%" ml="auto" mr={{ mobile: 'auto', desktop: 0 }}>
                <LocalImage
                  src={imageHeader}
                  width="100%"
                  mx="auto"
                  maxWidth="591px"
                  alt="Vista Social for Enterprise"
                />
              </Flex>
            </Grid>

            <Flex
              mt={{ mobile: 'l', tablet: 'm' }}
              pt="m"
              flexDirection="column"
              alignItems="center"
              mb={{ mobile: 'l', desktop: 'l' }}
            >
              <G2FeaturesComponent />
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <SecurePlatformComponent />

      <GetStartedComponent />

      <ExpectComponent />

      <IntegrationsComponent />

      <FeaturesComponent title="Enterprise" />

      <GetStartedComponent2 />

      {/* <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
            Frequently Asked{' '}
            <H3 as="span" fontSize="3xl" fontWeight="bold" color="primary">
              Questions
            </H3>
          </H3>
          <Flex flexDirection="column" mt="xl" width="100%">
            <GridDropdown faqs={FREQUENTLY_ASKED_QUESTIONS.enterprise} />
            <Flex mt="l" justifyContent="flex-end">
              <StyledLinkText as="a" href="/faq" textAlign="left">
                <Text fontSize="l" fontWeight="medium" textAlign="left" color="primary" as="span">
                  ALL OUR FAQS
                </Text>
                <LocalImage src={imageDoubleArrowRightBlue} mt="-2px" />
              </StyledLinkText>
            </Flex>
          </Flex>
        </Container>
      </Wrapper> */}

      <ExploreFullPlatform />

      <Footer />
    </App>
  )
}

export default SolutionsEnterprise
